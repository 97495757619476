import React from "react";

const DarkmodeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    className="sm:w-9 w-5 h-auto"
  >
    <path
      d="M19.4913 4.79558C19.9021 4.23982 19.9482 3.49459 19.6088 2.89253C19.2695 2.29046 18.6082 1.94387 17.92 2.00747C14.6039 2.31393 11.4435 3.55848 8.80888 5.5955C6.17422 7.63253 4.17419 10.3778 3.0428 13.51C1.91142 16.6422 1.69548 20.0319 2.42026 23.2824C3.14504 26.5329 4.78056 29.5097 7.13544 31.8646C9.49031 34.2194 12.4672 35.855 15.7176 36.5797C18.9681 37.3045 22.3578 37.0886 25.49 35.9572C28.6222 34.8258 31.3675 32.8258 33.4045 30.1911C35.4415 27.5565 36.6861 24.3961 36.9925 21.08C37.0561 20.3918 36.7095 19.7305 36.1075 19.3912C35.5054 19.0519 34.7602 19.0979 34.2044 19.5087C32.1782 21.0064 29.6816 21.7271 27.1689 21.5397C24.6561 21.3524 22.2941 20.2693 20.5124 18.4876C18.7307 16.7059 17.6477 14.3439 17.4603 11.8311C17.2729 9.31838 17.9936 6.82185 19.4913 4.79558Z"
      fill="#3F2193"
    />
    <path
      d="M31.3034 3.24362C31.4646 2.4931 32.5354 2.49309 32.6966 3.24362L32.8715 4.05788C33.1779 5.4848 34.2925 6.59949 35.7194 6.90608L36.5342 7.08115C37.2847 7.24242 37.2846 8.31331 36.534 8.47439L35.7203 8.64902C34.293 8.95535 33.1779 10.0703 32.8714 11.4976L32.6966 12.3119C32.5355 13.0624 31.4645 13.0624 31.3034 12.3119L31.1285 11.4976C30.8221 10.0703 29.7071 8.95535 28.2797 8.64902L27.466 8.47439C26.7154 8.31332 26.7153 7.24242 27.4658 7.08115L28.2806 6.90608C29.7075 6.59949 30.8221 5.4848 31.1285 4.05788L31.3034 3.24362Z"
      fill="#3F2193"
    />
  </svg>
);

export default DarkmodeIcon;
