import React from "react";

const SidePathology = () => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.16648 4.78359C8.16648 4.45222 8.43511 4.18359 8.76648 4.18359H9.96647C10.2978 4.18359 10.5665 4.45222 10.5665 4.78359V4.98359H11.5665C11.8978 4.98359 12.1665 5.25222 12.1665 5.58359V7.01126C12.1733 7.01168 12.18 7.0122 12.1868 7.01284C13.876 7.17231 15.4388 7.97617 16.5509 9.25753C17.663 10.5389 18.2389 12.1993 18.1592 13.8941C18.0793 15.5889 17.3499 17.1878 16.1222 18.359C15.8831 18.5871 15.6286 18.7957 15.3612 18.9835H17.9664C18.2978 18.9835 18.5664 19.2522 18.5664 19.5835C18.5664 19.9149 18.2978 20.1835 17.9664 20.1835H11.5898C11.582 20.1835 11.5742 20.1836 11.5665 20.1836C11.5641 20.1836 11.5617 20.1835 11.5593 20.1835H4.7665C4.43513 20.1835 4.1665 19.9149 4.1665 19.5835C4.1665 19.2522 4.43513 18.9835 4.7665 18.9835H11.588C12.9684 18.978 14.2947 18.444 15.2939 17.4907C16.2984 16.5325 16.8952 15.2243 16.9604 13.8376C17.0258 12.451 16.5545 11.0924 15.6447 10.0441C14.7551 9.01911 13.513 8.36757 12.1665 8.21705V12.3836C12.1665 12.7149 11.8978 12.9836 11.5665 12.9836H10.9665V13.7835C10.9665 14.6672 10.2502 15.3835 9.36648 15.3835C8.48283 15.3835 7.76649 14.6672 7.76649 13.7835V12.9836H7.16649C6.83512 12.9836 6.56649 12.7149 6.56649 12.3836V5.58359C6.56649 5.25222 6.83512 4.98359 7.16649 4.98359H8.16648V4.78359ZM8.96648 12.9836V13.7835C8.96648 14.0044 9.14557 14.1835 9.36648 14.1835C9.58739 14.1835 9.76648 14.0044 9.76648 13.7835V12.9836H8.96648ZM6.36649 16.5835C6.03513 16.5835 5.7665 16.8522 5.7665 17.1835C5.7665 17.5149 6.03513 17.7835 6.36649 17.7835H12.3665C12.6978 17.7835 12.9665 17.5149 12.9665 17.1835C12.9665 16.8522 12.6978 16.5835 12.3665 16.5835H6.36649Z"
      fill="#8C8C8C"
      className=" dark:group-hover:fill-white group-hover:fill-[#5E44FF]"
    />
  </svg>
);

export default SidePathology;
