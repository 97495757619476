import React from "react";

const KeySkeleton = () => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.283 2.20703V4.17258C13.9548 4.38572 15.5086 5.14798 16.7003 6.33972C17.8921 7.53146 18.6543 9.08519 18.8675 10.757H20.833V12.657H18.8675C18.6545 14.3289 17.8923 15.8828 16.7005 17.0745C15.5087 18.2663 13.9549 19.0285 12.283 19.2415V21.207H10.383V19.2415C8.71111 19.0285 7.15728 18.2663 5.96552 17.0745C4.77375 15.8828 4.01154 14.3289 3.79856 12.657H1.83301V10.757H3.79856C4.01169 9.08519 4.77396 7.53146 5.96569 6.33972C7.15743 5.14798 8.71117 4.38572 10.383 4.17258V2.20703H12.283ZM5.63301 11.707C5.63301 14.8506 8.18946 17.407 11.333 17.407C14.4766 17.407 17.033 14.8506 17.033 11.707C17.033 8.56348 14.4766 6.00703 11.333 6.00703C8.18946 6.00703 5.63301 8.56348 5.63301 11.707ZM14.183 11.707C14.183 13.281 12.907 14.557 11.333 14.557C9.759 14.557 8.48301 13.281 8.48301 11.707C8.48301 10.133 9.759 8.85698 11.333 8.85698C12.907 8.85698 14.183 10.133 14.183 11.707Z"
      fill="#8C8C8C"
      className=" dark:group-hover:fill-white group-hover:fill-[#5E44FF]"
    />
  </svg>
);

export default KeySkeleton;
