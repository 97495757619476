import React from "react";

const SemanticSegmentation = () => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.51221 3.83984H4.39307C4.06465 3.83984 3.79932 4.10518 3.79932 4.43359V8.58984C3.79932 8.75313 3.93291 8.88672 4.09619 8.88672H4.98682C5.1501 8.88672 5.28369 8.75313 5.28369 8.58984V5.32422H8.51221C8.67549 5.32422 8.80908 5.19062 8.80908 5.02734V4.13672C8.80908 3.97344 8.67549 3.83984 8.51221 3.83984ZM19.2368 14.5273H18.3462C18.1829 14.5273 18.0493 14.6609 18.0493 14.8242V18.0898H14.8208C14.6575 18.0898 14.5239 18.2234 14.5239 18.3867V19.2773C14.5239 19.4406 14.6575 19.5742 14.8208 19.5742H18.9399C19.2684 19.5742 19.5337 19.3089 19.5337 18.9805V14.8242C19.5337 14.6609 19.4001 14.5273 19.2368 14.5273ZM8.51221 18.0898H5.28369V14.8242C5.28369 14.6609 5.1501 14.5273 4.98682 14.5273H4.09619C3.93291 14.5273 3.79932 14.6609 3.79932 14.8242V18.9805C3.79932 19.3089 4.06465 19.5742 4.39307 19.5742H8.51221C8.67549 19.5742 8.80908 19.4406 8.80908 19.2773V18.3867C8.80908 18.2234 8.67549 18.0898 8.51221 18.0898ZM18.9399 3.83984H14.8208C14.6575 3.83984 14.5239 3.97344 14.5239 4.13672V5.02734C14.5239 5.19062 14.6575 5.32422 14.8208 5.32422H18.0493V8.58984C18.0493 8.75313 18.1829 8.88672 18.3462 8.88672H19.2368C19.4001 8.88672 19.5337 8.75313 19.5337 8.58984V4.43359C19.5337 4.10518 19.2684 3.83984 18.9399 3.83984Z"
      fill="#8C8C8C"
      className=" dark:group-hover:fill-white group-hover:fill-[#5E44FF]"
    />
  </svg>
);

export default SemanticSegmentation;
