import React from "react";

const ImageClassification = () => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2629 9.41758L19.7865 9.41758C19.9563 9.41758 20.0938 9.28008 20.0938 9.11035V3.58457C20.0938 3.41484 19.9563 3.27734 19.7865 3.27734L14.2629 3.27734C14.0932 3.27734 13.9557 3.41484 13.9557 3.58457V5.58047H11.0402C10.9564 5.58047 10.8877 5.64922 10.8877 5.73301V10.4918H9.04648V8.41855C9.04648 8.24883 8.90898 8.11133 8.73926 8.11133H3.21348C3.04375 8.11133 2.90625 8.24883 2.90625 8.41855L2.90625 13.9443C2.90625 14.1141 3.04375 14.2516 3.21348 14.2516L8.73711 14.2516C8.90684 14.2516 9.04434 14.1141 9.04434 13.9443V11.8711H10.8855V16.6299C10.8855 16.7137 10.9543 16.7824 11.0381 16.7824H13.9535V18.7783C13.9535 18.948 14.091 19.0855 14.2607 19.0855L19.7844 19.0855C19.9541 19.0855 20.0916 18.948 20.0916 18.7783L20.0916 13.2568C20.0916 13.0871 19.9541 12.9496 19.7844 12.9496L14.2629 12.9496C14.0932 12.9496 13.9557 13.0871 13.9557 13.2568V15.4053H12.267L12.267 6.96191H13.9557L13.9557 9.11035C13.9557 9.28008 14.0932 9.41758 14.2629 9.41758ZM15.4123 4.73613L18.635 4.73613V7.96094L15.4123 7.96094V4.73613ZM7.5877 12.7949H4.36504L4.36504 9.57227H7.5877L7.5877 12.7949ZM15.4123 14.4084H18.635V17.6332H15.4123V14.4084Z"
      fill="#8C8C8C"
      className=" dark:group-hover:fill-white group-hover:fill-[#5E44FF]"
    />
  </svg>
);

export default ImageClassification;
