import React from "react";
import CustomButton from "../CustomButton";
import { Chevron } from "../../assets/icons";
import SemanticSegmentation from "../../assets/icons/AnnotationToolIcons/semanticSegmentation";
import ObjectDetection from "../../assets/icons/AnnotationToolIcons/ObjectDetection";
import KeySkeleton from "../../assets/icons/AnnotationToolIcons/KeySkeleton";
import PolyAnnotation from "../../assets/icons/AnnotationToolIcons/PolyAnnotation";
import HangingProtocols from "../../assets/icons/AnnotationToolIcons/HangingProtocols";
import InstanceSegmentation from "../../assets/icons/AnnotationToolIcons/InstanceSegmentation";
import SidePathology from "../../assets/icons/AnnotationToolIcons/SidePathology";
import ImageClassification from "../../assets/icons/AnnotationToolIcons/ImageClassification";
import ActionRecognition from "../../assets/icons/AnnotationToolIcons/ActionRecognition";

const AnnotationTools = ({ data }) => {
  const toolsData = [
    {
      toolIcon: <SemanticSegmentation />,
    },
    {
      toolIcon: <ObjectDetection />,
    },
    {
      toolIcon: <KeySkeleton />,
    },
    {
      toolIcon: <PolyAnnotation />,
    },
    {
      toolIcon: <HangingProtocols />,
    },
    {
      toolIcon: <InstanceSegmentation />,
    },
    {
      toolIcon: <SidePathology />,
    },
    {
      toolIcon: <ImageClassification />,
    },
    {
      toolIcon: <ActionRecognition />,
    },
  ];
  return (
    <div className="flex flex-col gap-6 overflow-x-auto lg:gap-12 lg:items-center lg:flex-row ">
      <div>
        <p className="flex text-xs sm:text-sm font-semibold tracking-wide uppercase font-manrope text-purple-1500 dark:text-[#FFD643] ">
          {data?.primary?.label?.text}
        </p>
        <h1 className="font-manrope tracking-tight mt-3.5 font-semibold  text-2xl sm:text-2.5xl sm:max-w-[530px] lg:max-w-[410px] leading-9 text-purple-1100 dark:text-white">
          {data?.primary?.section_heading?.text}
        </h1>
        <p className="mt-6  text-sm sm:text-base font-normal leading-6 text-gray-2500 dark:text-gray-2200 tracking-tighter_3 sm:max-w-[450px] lg:max-w-[410px] font-inter ">
          {data?.primary?.section_description?.text}
        </p>
        <CustomButton
          text={data?.primary?.cta_text?.text}
          link={data?.primary?.cta_link?.url}
          icon={<Chevron fill={`purple`} />}
          direction={"right"}
          buttonClass={`group bg-gray-1600 hover:!bg-gray-2900 dark:hover:!bg-purple-1100 !text-gray-2500 text-base dark:!bg-[#9c8cff4d] dark:!text-purple-1900 mt-6 sm:mt-12`}
        />
      </div>
      <div className="flex lg:grid lg:grid-cols-3 gap-x-1.5 overflow-x-auto HideScrollBar gap-y-1.5">
        {data?.items?.map((value, index) => (
          <div
            className="flex flex-col group rounded-md min-w-[227px] grid_width_style lg:hover:font-normal xl:hover:font-medium
             lg:max-w-[227px] w-full items-center gap-2.5 px-2.5 py-5 border border-gray-1600 font-normal text-gray-600 dark:text-gray-1200  hover:font-medium hover:text-purple-1500 dark:hover:text-white dark:border-[#f0f0f01a] dark:hover:bg-[#9c8cff26] hover:bg-[#EEEBFF;] cursor-pointer"
            key={index}
          >
            {toolsData[index].toolIcon}
            <p className="text-base leading-6 text-center font-inter tracking-tighter_3 ">
              {value?.label?.text}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AnnotationTools;
