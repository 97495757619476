import React from "react";
export const WhiteCheck = ({ iconColourStyle = "stroke-purple-1500 dark:stroke-white" }) => {
  return (
    <svg
      width="129"
      height="28"
      viewBox="0 0 129 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={` h-auto sm:w-32 ${iconColourStyle}`}
    >
      <g id="Frame 1000002978">
        <path
          id="Vector 358"
          d="M58.5 13.3258L62.5 18.0011L70.5 9.42969"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );

};
