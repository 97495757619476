import React from "react";
export const WhiteCheckMobile = ({ iconColourStyle = "stroke-purple-1500 dark:stroke-white" }) => {
    return (
        <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg" className={`${iconColourStyle}`}>
            <path d="M1 5.28673L5 9.96205L13 1.39062" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    );

};
