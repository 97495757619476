import React, { useRef } from "react";
import { WhiteCheck } from "../../assets/icons/WhiteCheck";
import { useMediaQuery } from "react-responsive";
import EncordLogo from "../../assets/icons/AnnotationTypes/EncordLogo";

const TableBodyRowSection = ({
  tableBodyRowData,
  openFeature,
  activePlan,
  tabletLength,
  mobileLength,
  desktopLength,
  smallDesktoplength,
  darkMode,
}) => {
  const table = useRef(null);
  const isMobile = useMediaQuery({ maxWidth: 400 });
  const isTablet = useMediaQuery({ maxWidth: 820 });
  const isSmallDesktop = useMediaQuery({ maxWidth: 1023 });

  const tableHeight = table?.current?.scrollHeight;
  const maxHeight = isMobile
    ? tableHeight / mobileLength
    : isTablet
    ? tableHeight / tabletLength
    : isSmallDesktop
    ? tableHeight / smallDesktoplength
    : tableHeight / desktopLength;

  return (
    <div
      className={`font-inter mt-2.5 overflow-hidden transition-all duration-500 ease-in-out`}
      ref={table}
      aria-expanded={openFeature}
    >
      <div className="flex justify-center gap-6 lg:items-center font-inter lg:justify-items-end encord_coloum_styling">
        <div className=" md:py-3 py-2.5 pb-9 w-full lg:max-w-sm lg:pl-7.5 lg:pr-0 sm:px-5 pl-4 pr-0">
          <p className="max-w-[320px] text-base text-gray-2500 dark:text-gray-2200 tracking-tighter_3 sm:font-medium">
            <span></span>
          </p>
        </div>

        <div className={`flex w-full justify-center coloumn_sections lg:gap-6`}>
          <div
            className={`${
              activePlan == 0 ? "flex" : "hidden"
            } sm:flex  justify-center encord_background_style lg:items-center py-3 pb-9 w-full px-1.5 `}
          >
            {darkMode ? (
             <img
              src={tableBodyRowData?.primary?.encord_logo?.url}
              loading="eager"
              width={0}
              height={0}
              alt="stats"
              className="sm:min-w-[127px] min-w-[87px]"
            />
            ) : (
              <EncordLogo /> 
            )}
          </div>
          <div
            className={`${
              activePlan == 1 ? "flex" : "hidden"
            } sm:flex justify-center lg:items-center py-3 pb-9  w-full px-1.5 itk_background_style`}
          >
            <span className="flex justify-center text-sm font-medium text-gray-600 dark:text-gray-2200 sm:text-base table_text_width lg:w-auto">
              {tableBodyRowData?.primary?.competitor_name?.text}
            </span>
          </div>
        </div>
      </div>
      {tableBodyRowData?.items?.map((item, index) => {
        const featureNameArray = item?.feature_name?.text?.trim().split(" ");
        const featureName = featureNameArray?.join(" ");
        return (
          <React.Fragment key={index}>
            <div className="flex justify-center gap-6 lg:items-center font-inter lg:justify-items-end encord_coloum_styling">
              <div className=" md:py-3 py-2.5 pb-9 w-full lg:max-w-sm lg:pl-7.5 lg:pr-0 sm:px-5 pl-4 pr-0">
                <p className="max-w-[320px] text-base text-gray-2500 dark:text-gray-2200 tracking-tighter_3 sm:font-medium">
                  <span>{featureName}</span>
                </p>
              </div>

              <div
                className={`flex w-full justify-center coloumn_sections lg:gap-6 ${
                  item?.feature_name?.text?.length >= 39 && "lg:h-18 "
                }`}
              >
                <div
                  className={`${
                    activePlan == 0 ? "flex" : "hidden"
                  } sm:flex  justify-center encord_background_style lg:items-center py-3 pb-9 w-full px-1.5 `}
                >
                  {item?.encord_logo?.url ? (
                    <img
                      src={item?.encord_logo?.url}
                      loading="eager"
                      width={0}
                      height={0}
                      alt="stats"
                      className="sm:min-w-[127px] min-w-[87px]"
                    />
                  ) : item?.encord_score?.text === "check" ? (
                    <div className="flex justify-center table_text_width lg:w-auto">
                      <WhiteCheck
                        iconColourStyle={"stroke-purple-1500 dark:stroke-white"}
                      />
                    </div>
                  ) : (
                    <span className="flex justify-center text-sm font-medium text-gray-600 dark:text-gray-2200 sm:text-2xl table_text_width lg:w-auto">
                      {item?.encord_column_text?.text
                        ? item?.encord_column_text?.text
                        : "-"}
                    </span>
                  )}
                </div>
                <div
                  className={`${
                    activePlan == 1 ? "flex" : "hidden"
                  } sm:flex justify-center lg:items-center py-3 pb-9  w-full px-1.5 itk_background_style`}
                >
                  {item?.competitor_score?.text === "check" ? (
                    <div className="flex justify-center table_text_width lg:w-auto">
                      <WhiteCheck iconColourStyle="stroke-gray-600 dark:stroke-white" />
                    </div>
                  ) : (
                    <span className="flex justify-center text-sm font-medium text-gray-600 dark:text-gray-2200 sm:text-base table_text_width lg:w-auto">
                      {item?.itk_column_text?.text
                        ? item?.itk_column_text?.text
                        : "-"}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default TableBodyRowSection;
