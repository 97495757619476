import React from "react";

const LightmodeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    className="sm:w-9 w-5 h-auto"
  >
    <path
      d="M17.9913 3.29558C18.4021 2.73982 18.4482 1.99459 18.1088 1.39253C17.7695 0.790456 17.1082 0.443873 16.42 0.507469C13.1039 0.813925 9.94354 2.05848 7.30888 4.0955C4.67422 6.13253 2.67419 8.87777 1.5428 12.01C0.41142 15.1422 0.195484 18.5319 0.920263 21.7824C1.64504 25.0329 3.28056 28.0097 5.63544 30.3646C7.99031 32.7194 10.9672 34.355 14.2176 35.0797C17.4681 35.8045 20.8578 35.5886 23.99 34.4572C27.1222 33.3258 29.8675 31.3258 31.9045 28.6911C33.9415 26.0565 35.1861 22.8961 35.4925 19.58C35.5561 18.8918 35.2095 18.2305 34.6075 17.8912C34.0054 17.5519 33.2602 17.5979 32.7044 18.0087C30.6782 19.5064 28.1816 20.2271 25.6689 20.0397C23.1561 19.8524 20.7941 18.7693 19.0124 16.9876C17.2307 15.2059 16.1477 12.8439 15.9603 10.3311C15.7729 7.81838 16.4936 5.32185 17.9913 3.29558Z"
      fill="#595959"
    />
  </svg>
);

export default LightmodeIcon;
