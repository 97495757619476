import React from "react";

const EncordLogo = () => (
  <svg
    width="122"
    height="44"
    viewBox="0 0 122 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5034 21.3955L18.5009 21.6043C18.4934 22.2496 18.3936 22.9021 18.2065 23.5426C17.8223 24.8522 17.0713 26.0456 15.9761 27.0847C14.8809 28.1263 13.6235 28.8427 12.2389 29.2152C11.5703 29.3955 10.8892 29.4904 10.2106 29.5023C9.49956 29.5142 8.77606 29.4288 8.07003 29.2508C6.75525 28.921 5.56023 28.2734 4.5149 27.3244L17.5204 14.9566L17.3632 14.8072C16.0485 13.5569 14.5291 12.7099 12.8526 12.2947C11.9844 12.0788 11.0938 11.9815 10.2056 12.0029C9.39478 12.0242 8.58146 12.1429 7.78562 12.3588C6.10411 12.8166 4.57229 13.6968 3.23257 14.9709C1.89285 16.2449 0.972256 17.7063 0.495745 19.3101C0.273706 20.0622 0.148965 20.8333 0.129006 21.5996C0.106553 22.4489 0.208842 23.303 0.438365 24.1334C0.874959 25.7277 1.75812 27.1654 3.06042 28.4038C4.36272 29.6423 5.87458 30.4821 7.5511 30.8973C8.34446 31.0942 9.15777 31.1939 9.97358 31.1939C10.0534 31.1939 10.1357 31.1939 10.2181 31.1915C11.0239 31.1725 11.8347 31.0539 12.6256 30.8428C14.3146 30.3896 15.8489 29.5142 17.1886 28.2401C18.5283 26.9661 19.4514 25.5094 19.9354 23.9104C20.1624 23.1559 20.2897 22.3801 20.3121 21.6114L20.3171 21.3955H18.5034ZM14.9507 15.163L3.41968 26.1286C2.84337 25.3671 2.42674 24.5343 2.17975 23.647C1.99264 22.9756 1.90283 22.2923 1.9153 21.6138C1.92528 20.9709 2.02757 20.3208 2.21717 19.685C2.60886 18.3682 3.3623 17.1725 4.45752 16.131C5.55275 15.0919 6.80515 14.3777 8.18229 14.01C8.85589 13.8321 9.54197 13.7372 10.2206 13.73C10.2505 13.73 10.2804 13.73 10.3104 13.73C10.9865 13.73 11.6701 13.8155 12.3412 13.9839C13.2717 14.2188 14.1499 14.615 14.9507 15.163Z"
      fill="#262626"
    />
    <path
      d="M37.9882 12.3516V27.6612L25.3843 12.3516H23.8875V30.8617H25.7162V15.5544L38.3474 30.8617H39.8194V12.3516H37.9882Z"
      fill="#262626"
    />
    <path
      d="M59.112 27.2467C57.6226 28.6465 55.7091 29.3559 53.4263 29.3559C51.877 29.3559 50.4525 29.0166 49.1876 28.3499C47.9252 27.6833 46.9198 26.7485 46.2013 25.5741C45.4828 24.3974 45.1161 23.0641 45.1161 21.6121C45.1161 20.1578 45.4803 18.8244 46.2013 17.6477C46.9198 16.4733 47.9252 15.5385 49.1876 14.8719C50.4525 14.2028 51.8795 13.8659 53.4263 13.8659C55.7265 13.8659 57.6401 14.5682 59.112 15.949L59.2717 16.0984L60.4442 14.9478L60.2996 14.8007C59.4663 13.9537 58.4409 13.306 57.2559 12.8742C56.0758 12.4448 54.7685 12.2266 53.3739 12.2266C51.4804 12.2266 49.7465 12.6346 48.2246 13.4413C46.7003 14.2503 45.4853 15.3796 44.6096 16.8007C43.7339 18.2218 43.2874 19.8399 43.2874 21.6097C43.2874 23.3796 43.7314 24.9976 44.6096 26.4187C45.4853 27.8399 46.7028 28.9715 48.2246 29.7782C49.7465 30.5848 51.4779 30.9929 53.3739 30.9929C54.7536 30.9929 56.0559 30.7699 57.2434 30.3333C58.4384 29.8944 59.4663 29.242 60.302 28.395L60.4467 28.2479L59.2742 27.0973L59.112 27.2467Z"
      fill="#262626"
    />
    <path
      d="M80.3399 16.7988C79.4617 15.3776 78.2417 14.2483 76.7099 13.4393C75.1781 12.6327 73.4417 12.2246 71.5481 12.2246C69.652 12.2246 67.9156 12.6374 66.3863 13.4536C64.8545 14.2697 63.6345 15.4037 62.7563 16.8248C61.8807 18.246 61.4341 19.8569 61.4341 21.6078C61.4341 23.361 61.8782 24.9696 62.7563 26.3907C63.632 27.8118 64.8545 28.9459 66.3863 29.762C67.9156 30.5781 69.6545 30.9909 71.5481 30.9909C73.4442 30.9909 75.1781 30.5829 76.7099 29.7762C78.2417 28.9696 79.4617 27.8379 80.3399 26.4168C81.2155 24.9933 81.6596 23.3753 81.6596 21.6078C81.6621 19.8403 81.2155 18.2222 80.3399 16.7988ZM79.8035 21.6078C79.8035 23.0621 79.4392 24.3954 78.7182 25.5698C77.9997 26.7466 77.0018 27.6813 75.7594 28.348C74.5119 29.0147 73.0974 29.3539 71.5481 29.3539C69.9988 29.3539 68.5768 29.0147 67.3219 28.348C66.067 27.6813 65.0665 26.7466 64.348 25.5698C63.6295 24.3931 63.2628 23.0597 63.2628 21.6078C63.2628 20.1534 63.627 18.8201 64.348 17.6434C65.0665 16.469 66.067 15.5342 67.3219 14.8652C68.5768 14.1985 69.9988 13.8592 71.5481 13.8592C73.0949 13.8592 74.5119 14.1985 75.7594 14.8652C77.0043 15.5318 77.9997 16.4666 78.7182 17.6434C79.4392 18.8225 79.8035 20.1558 79.8035 21.6078Z"
      fill="#262626"
    />
    <path
      d="M95.7379 24.4346C97.0676 24.017 98.1154 23.3314 98.8564 22.3919C99.6522 21.386 100.056 20.1309 100.056 18.66C100.056 16.6908 99.3429 15.1274 97.9383 14.0147C96.5437 12.9115 94.6052 12.3516 92.1728 12.3516H85.1299V30.8617H86.9611V24.9447H92.1728C92.7067 24.9447 93.2929 24.9067 93.9167 24.8308L98.4423 30.8617H100.565L95.7379 24.4346ZM98.1978 18.6624C98.1978 20.157 97.6863 21.3243 96.6734 22.1309C95.6506 22.9471 94.1362 23.3599 92.1703 23.3599H86.9586V13.9648H92.1703C94.1362 13.9648 95.6506 14.3777 96.6734 15.1938C97.6863 16.0004 98.1978 17.1677 98.1978 18.6624Z"
      fill="#262626"
    />
    <path
      d="M120.567 16.8355C119.698 15.4382 118.466 14.3302 116.907 13.5402C115.35 12.7525 113.546 12.3516 111.545 12.3516H103.796V30.8617H111.545C113.546 30.8617 115.35 30.4631 116.907 29.673C118.466 28.883 119.698 27.7751 120.567 26.3777C121.435 24.9826 121.874 23.3765 121.874 21.6066C121.874 19.8367 121.435 18.2329 120.567 16.8355ZM105.625 13.9648H111.436C113.147 13.9648 114.676 14.2923 115.979 14.9352C117.276 15.5781 118.286 16.4844 118.987 17.6303C119.688 18.781 120.045 20.1191 120.045 21.6066C120.045 23.0942 119.688 24.4322 118.987 25.5829C118.289 26.7312 117.276 27.6375 115.979 28.278C114.676 28.921 113.147 29.2484 111.436 29.2484H105.625V13.9648Z"
      fill="#262626"
    />
  </svg>
);
export default EncordLogo;
