import React from "react";

const ActionRecognition = () => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.8824 7.2385C15.7001 7.2385 16.363 6.57562 16.363 5.75792C16.363 4.94022 15.7001 4.27734 14.8824 4.27734C14.0647 4.27734 13.4019 4.94022 13.4019 5.75792C13.4019 6.57562 14.0647 7.2385 14.8824 7.2385Z"
      fill="#8C8C8C"
      className=" dark:group-hover:fill-white group-hover:fill-[#5E44FF]"
    />
    <path
      d="M18.473 7.79397C18.1028 7.6089 17.6587 7.73845 17.4736 8.1086L16.8258 9.33007L13.9757 7.53487C13.8647 7.46084 13.7351 7.42383 13.5871 7.42383H10.4408C10.1632 7.42383 9.92264 7.57189 9.79309 7.81248L8.59012 10.0333C8.38654 10.385 8.5346 10.8477 8.88624 11.0327C8.99728 11.0883 9.12683 11.1253 9.23787 11.1253C9.49698 11.1253 9.75608 10.9772 9.88563 10.7366L10.885 8.90441H11.9769L8.70116 15.0118H5.62896C5.2218 15.0118 4.88867 15.3449 4.88867 15.7521C4.88867 16.1592 5.2218 16.4924 5.62896 16.4924H9.14534C9.42295 16.4924 9.66354 16.3443 9.79309 16.1037L11.0886 13.7163L13.2169 15.6966L13.0504 19.4165C13.0134 19.8237 13.328 20.1753 13.7351 20.1938C13.7536 20.1938 13.7536 20.1938 13.7721 20.1938C14.1608 20.1938 14.4939 19.8792 14.5124 19.4906L14.6975 15.419C14.716 15.1969 14.6235 14.9933 14.4569 14.8452L12.6617 13.1796L14.5495 9.6632L16.6963 11.0142C16.8629 11.1253 17.0849 11.1623 17.2885 11.1068C17.4921 11.0512 17.6587 10.9217 17.7512 10.7366L18.7691 8.79336C18.9727 8.44173 18.8431 7.99755 18.473 7.79397Z"
      fill="#8C8C8C"
      className=" dark:group-hover:fill-white group-hover:fill-[#5E44FF]"
    />
    <path
      d="M1.83301 8.3616V2.18359H8.01102"
      stroke="#8C8C8C"
      className=" dark:group-hover:fill-white group-hover:fill-[#5E44FF]"
    />
    <path
      d="M21.833 8.3616V2.18359H15.655"
      stroke="#8C8C8C"
      className=" dark:group-hover:fill-white group-hover:fill-[#5E44FF]"
    />
    <path
      d="M1.83301 16.0056V22.1836H8.01102"
      stroke="#8C8C8C "
      className=" dark:group-hover:fill-white group-hover:fill-[#5E44FF]"
    />
    <path
      d="M21.833 16.0056V22.1836H15.655"
      stroke="#8C8C8C"
      className=" dark:group-hover:fill-white group-hover:fill-[#5E44FF]"
    />
  </svg>
);

export default ActionRecognition;
