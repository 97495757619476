import React, { useState } from "react";
import { AnnotationTypesChevron } from "../../assets/icons";
import CustomButton from "../CustomButton";
import { useMediaQuery } from "react-responsive";
import { navigate } from "gatsby-link";
import Video from "../home_Pages/Video";
import Dicom from "../../assets/icons/AnnotationTypes/Dicom";
import AnnotationTypeImage from "../../assets/icons/AnnotationTypes/Image";
import AnnotationTypeVideo from "../../assets/icons/AnnotationTypes/Video";
import EcgAnnotation from "../../assets/icons/AnnotationTypes/ECGannotation";
import DocumentsIcon from "../../assets/icons/AnnotationTypes/Documents";

const AnnotationTypes = ({
  annotationTypes,
  backGroundStyle = "bg-white border-gray-1600",
  darkMode,
}) => {
  const [activeAnnotation, setActiveAnnotation] = useState(0);
  const isDesktop = useMediaQuery({ minWidth: 1000 });
  const TypeIcons = [
    {
      icon: <Dicom />,
    },
    {
      icon: <AnnotationTypeImage />,
    },
    {
      icon: <AnnotationTypeVideo />,
    },
    {
      icon: <EcgAnnotation />,
    },
    {
      icon: <DocumentsIcon />,
    },
  ];

  return (
    <section
      className={`flex flex-col lg:items-center lg:py-12 sm:py-8 py-5 gap-x-12 lg:px-10 sm:px-6 px-3.5 ${backGroundStyle} lg:flex-row rounded-2.5xl border `}
    >
      <div className="flex flex-col justify-between lg:max-w-[420px] lg:w-full">
        <div className="">
          <div className="flex flex-col lg:max-w-[460px] w-full">
            <h3
              className={`text-sm tracking-widest_1 font-semibold uppercase text-purple-1500  dark:text-[#FFD643]`}
            >
              annotation types
            </h3>
            <p
              className={`text-purple-1100 dark:text-white sm:text-2.5xl text-2xl font-semibold !tracking-tighter_3 mt-4`}
            >
              {annotationTypes?.primary?.section_heading?.text}
            </p>
            <p
              className={`sm:text-base text-sm text-gray-2500 dark:text-gray-1200 font-inter sm:mt-6 mt-3.5 max-w-[450px] lg:max-w-[450px]`}
            >
              {annotationTypes?.primary?.section_description?.text}
            </p>
          </div>
          <div className="flex flex-col lg:max-w-md lg:gap-2 gap-2.5 md:justify-start lg:mt-10 sm:mt-8 mt-6">
            {annotationTypes?.items?.map(
              ({ label, coming_soon, label_link }, index) => {
                return (
                  <button
                    className={`flex items-center group justify-between lg:p-2 sm:px-2 sm:py-2.5 py-2 ${
                      activeAnnotation == index
                        ? "lg:bg-gray-1500 lg:dark:bg-[#53426f] text-gray-2500 dark:text-white rounded-lg"
                        : " lg:text-gray-1200 dark:lg:text-gray-1200 text-gray-2500 dark:text-white"
                    } rounded-md cursor-pointer`}
                    key={index}
                    onClick={() => {
                      !coming_soon && navigate(label_link?.url);
                    }}
                    onMouseEnter={() => {
                      coming_soon ? "" : setActiveAnnotation(index);
                    }}
                  >
                    <div className={`flex items-center gap-x-2`}>
                      <div
                        className={`${
                          activeAnnotation !== index &&
                          !darkMode &&
                          isDesktop &&
                          "img_grayscale opacity-50"
                        }`}
                      >
                        {React.cloneElement(TypeIcons[index].icon, {
                          fill:
                            (activeAnnotation == index && darkMode) ||
                            (!isDesktop && darkMode)
                              ? "white"
                              : darkMode
                              ? "#8C8C8C"
                              : "#3F2193",
                        })}
                      </div>
                      <p
                        className={`text-base font-medium sm:text-xl font-inter`}
                      >
                        {label?.text}
                      </p>
                      {coming_soon && (
                        <div className="uppercase ml-2 sm:text-xs text-2xs tracking-widest_1 font-semibold text-purple-1500 dark:text-[#9C8CFF] px-2.5 py-1.5 bg-purple-1600 dark:bg-purple-1600/20 font-manrope rounded-full">
                          coming soon
                        </div>
                      )}
                    </div>
                    <div
                      className={`${
                        activeAnnotation == index
                          ? "sm:block"
                          : coming_soon
                          ? "hidden"
                          : !isDesktop
                          ? "block"
                          : "sm:hidden"
                      }`}
                    >
                      <AnnotationTypesChevron
                        className={" dark:fill-white fill-[#434343]"}
                      />
                    </div>
                  </button>
                );
              }
            )}
          </div>
        </div>

        <div className="hidden lg:block">
          <CustomButton
            link={"https://encord.com/annotate/"}
            text={" Explore all annotation types"}
            icon={
              <AnnotationTypesChevron
                className={"dark:fill-[#C5BBFF] fill-[#434343]"}
              />
            }
            direction="right"
            buttonClass={`group bg-gray-1600 hover:!bg-gray-2900 dark:hover:!bg-purple-1100 !text-gray-2500 text-base dark:!bg-[#9c8cff4d] dark:!text-purple-1900 mt-6 sm:mt-12`}
          />
        </div>
      </div>
      <div className="hidden mt-8 lg:mt-0 sm:block">
        {annotationTypes?.items[activeAnnotation]?.label_slide_video?.url ? (
          <div className="justify-center lg:justify-end flex w-full h-auto duration-[1s] ease-in">
            <Video
              src={
                annotationTypes?.items[activeAnnotation]?.label_slide_video?.url
              }
              classes="rounded-2.5xl lg:w-[578px] max-h-[544.28px]"
            />
          </div>
        ) : (
          <img
            src={
              annotationTypes?.items[activeAnnotation]?.label_slide_image?.url
            }
            alt={
              annotationTypes?.items[activeAnnotation]?.label_slide_image
                ?.alt || "supporting annotation"
            }
            loading="lazy"
            className="rounded-2.5xl lg:max-h-[544.28px] sm:h-auto w-full lg:max-w-2xl"
          />
        )}
      </div>
      <div className="mt-6 lg:hidden sm:mt-9">
        <CustomButton
          link={"https://encord.com/annotate/"}
          text={" Explore all annotation types"}
          icon={
            <AnnotationTypesChevron
              className={" dark:fill-[#C5BBFF] fill-[#434343]"}
            />
          }
          direction="right"
          buttonClass="bg-gray-1600 dark:bg-[#9c8cff4c] dark:hover:bg-purple-1100 hover:bg-gray-2900 text-gray-2500 dark:text-white"
        />
      </div>
    </section>
  );
};

export default AnnotationTypes;
