import React from "react";

const PolyAnnotation = () => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.1181 17.2315C19.8203 15.4252 18.2158 14.1306 16.3879 14.2194C14.56 14.3083 13.0899 15.7567 12.9723 17.5822C9.28521 17.4669 7.97614 16.6046 8.18991 14.8512C8.26918 14.1954 8.6655 13.8447 9.3933 13.6742C9.83527 13.5709 10.3253 13.5565 10.7696 13.5973C10.8105 13.6021 10.8537 13.6045 10.8945 13.6093C11.166 13.6742 11.4422 13.7294 11.7184 13.7727C12.5375 13.9072 13.371 13.9744 14.1636 13.9312C16.3927 13.8111 17.9107 12.8287 17.9876 10.7438C18.062 8.72371 16.6737 7.29213 14.4351 6.33375C13.7169 6.02629 12.9554 5.7885 12.194 5.60835C11.8505 5.52668 11.5623 5.47143 11.3485 5.4354C11.1588 3.6123 9.6311 2.22156 7.79839 2.20714C5.96568 2.19273 4.414 3.55465 4.19302 5.37536C3.97204 7.19606 5.15381 8.88945 6.93608 9.3122C8.71835 9.73495 10.5366 8.75254 11.1564 7.02792C11.3821 7.06875 11.6055 7.11439 11.8289 7.16723C12.5063 7.32576 13.1836 7.53714 13.8081 7.80616C15.5063 8.53156 16.4335 9.48994 16.3903 10.6861C16.3518 11.7574 15.5832 12.2522 14.0796 12.3339C13.419 12.3699 12.696 12.3123 11.9826 12.1946C11.7833 12.1609 11.6007 12.1273 11.4398 12.0913C11.3461 12.0721 11.2813 12.0553 11.2524 12.0481L11.178 12.0336C11.1251 12.0264 11.0363 12.0144 10.921 12.0024C10.3205 11.9472 9.66953 11.9664 9.03541 12.1129C7.70952 12.4228 6.77995 13.2466 6.60701 14.6542C6.2299 17.6855 8.49496 19.0907 13.2365 19.1915C13.9354 20.8825 15.7922 21.7833 17.5504 21.2813C19.3111 20.7792 20.416 19.0378 20.1181 17.2315ZM7.76236 7.81096C6.65745 7.81096 5.76151 6.91502 5.76151 5.81011C5.76151 4.7052 6.65745 3.80926 7.76236 3.80926C8.86727 3.80926 9.76321 4.7052 9.76321 5.81011C9.76321 6.91502 8.86727 7.81096 7.76236 7.81096ZM16.5656 19.8161C15.4607 19.8161 14.5648 18.9201 14.5648 17.8152C14.5648 16.7103 15.4607 15.8144 16.5656 15.8144C17.6705 15.8144 18.5665 16.7103 18.5665 17.8152C18.5665 18.9201 17.6705 19.8161 16.5656 19.8161Z"
      fill="#8C8C8C"
      className=" dark:group-hover:fill-white group-hover:fill-[#5E44FF]"
    />
  </svg>
);

export default PolyAnnotation;
