import React from "react";
import { WhiteCheckMobile } from "../../assets/WhiteCheckMobile";
import EncordLogo from "../../assets/icons/AnnotationTypes/EncordLogo";

const TablebodyMobile = ({ tableBodyRowData, dark_light_bg, darkMode }) => {
  return (
    <div className="font-inter mt-2.5 overflow-hidden transition-all duration-500 ease-in-out flex flex-col gap-7.5 items-center">
      <div
        className={`flex flex-col justify-center items-center gap-3 p-6 w-full  border border-solid border-[#f0f0f0] dark:border-[#43239d33] rounded-2.5xl ${dark_light_bg}`}
      >
        {darkMode ? (
          <img
            src={tableBodyRowData?.primary?.encord_logo?.url}
            loading="eager"
            width={0}
            height={0}
            alt="stats"
            className="min-w-[127px] py-6"
          />
        ) : (
          <EncordLogo />
        )}
        {tableBodyRowData?.items?.map((item, index) => {
          const featureNameArray = item?.feature_name?.text?.trim().split(" ");
          const featureName = featureNameArray?.join(" ");
          return (
            <React.Fragment key={index}>
              {item?.encord_score?.text === "check" && (
                <div className="flex items-center justify-center w-full gap-3 px-3 ">
                  <div className="w-full sm:w-auto flex items-center gap-2.5 sm:gap-5">
                    <WhiteCheckMobile
                      iconColourStyle={"stroke-purple-1500 dark:stroke-white !"}
                    />
                    <p className="flex justify-center w-full py-3 text-base font-medium text-center text-gray-2500 dark:text-gray-2200 tracking-tighter_3 sm:w-auto">
                      <span className="block max-w-[194px] sm:max-w-full">
                        {featureName}
                      </span>
                    </p>
                  </div>
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
      <div className="p-2.5 vs_style dark:text-white">VS</div>
      <div
        className={`flex flex-col justify-center items-center gap-3 p-6 w-full  border border-solid border-[#f0f0f0] dark:border-[#43239d33] rounded-2.5xl ${dark_light_bg} `}
      >
        <span className="flex justify-center font-semibold text-gray-600 dark:text-gray-2200 text-2xl min-w-[127px] py-6">{tableBodyRowData?.primary?.competitor_name?.text}</span>
        {tableBodyRowData?.items?.map((item, index) => {
          const featureNameArray = item?.feature_name?.text?.trim().split(" ");
          const featureName = featureNameArray?.join(" ");
          return (
            <React.Fragment key={index}>
              {item?.competitor_score?.text === "check" && (
                <div className="py-2.5 px-2.5 w-full flex justify-center items-center">
                  <div className="w-full sm:w-auto flex gap-2.5 sm:gap-5 items-baseline">
                    <WhiteCheckMobile
                      iconColourStyle={"stroke-purple-1500 dark:stroke-white "}
                    />
                    <p className="flex justify-center w-full text-base font-medium text-center text-gray-2500 dark:text-gray-2200 tracking-tighter_3 sm:w-auto">
                      <span className="block max-w-[194px] sm:max-w-full">
                        {featureName}
                      </span>
                    </p>
                  </div>
                </div>
              ) }
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default TablebodyMobile;
