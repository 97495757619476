import React from "react";
import CustomButton from "../CustomButton";
import { Chevron } from "../../assets/icons";

const VerticalCardsSection = ({
  data,
  outlineColor,
  descriptionClass,
  darkMode,
}) => {
  return (
    <div className="flex flex-col justify-between lg:max-w-[564px] py-6 lg:py-10 rounded-2.5xl border dark:border-[#f0f0f04d] border-gray-1600 bg-white dark:bg-[#ffffff0d] px-6 vertical_card_width lg:!px-11">
      <div className="flex flex-col gap-3.5 md:gap-6 ">
        <img
          src={
            darkMode
              ? data?.section_card_image_dark?.url
              : data?.section_card_image?.url
          }
          alt="section_card_image"
          className="rounded-[10px]"
        />
        <p
          className={`text-xs lg:text-sm font-manrope font-semibold tracking-widest_1 uppercase ${outlineColor} `}
        >
          {data?.section_card_label?.text}
        </p>
        <h1 className="font-manrope tracking-[-1.2px] md:tracking-[-1.62px] leading-9 font-semibold text-2xl sm:text-2.5xl min-h-[72px] text-purple-1100 dark:text-white">
          {data?.section_card_heading?.text}
        </h1>
        <p
          className={`text-sm sm:text-base leading-6 text-gray-2500  dark:text-gray-2200 font-normal tracking-tighter_3 font-inter  ${descriptionClass}`}
        >
          {data?.section_card_description?.text}
        </p>
      </div>
      <CustomButton
        text={data?.section_card_cta_text?.text}
        icon={<Chevron fill={`purple`} />}
        link={data?.section_card_cta_link?.url}
        direction={"right"}
        buttonClass={`bg-gray-1600 mt-3.5 md:mt-6 hover:bg-gray-2900 dark:hover:bg-purple-1100 text-gray-2500 text-base dark:bg-[#9c8cff4d] dark:!text-purple-1900 tracking-tighter_2 `}
      />
    </div>
  );
};

export default VerticalCardsSection;
